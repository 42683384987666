/* page_header */
.banner_main {
  height: 100%;
  max-height: 320px;
  background: #4BA8C4 url("../img/bg/header_welcome_mobile.jpg") no-repeat center 30px;

  .banner {
    .heading {
      font-weight: bold;
      font-size: 32px;
      color: #fff;
      padding: 24px 0;
      text-align: center;

      br {
        display: none;
      }
    }
  }

  @media (min-width: 1100px) {
    max-height: 460px;
    background: #4BA8C4 url("../img/bg/header_welcome_desktop.jpg") no-repeat center;
    
    .banner {
      position: relative;
      height: 460px;

      .heading {
        font-size: 64px;
        text-align: left;
        padding: 30px 0;

        br {
          display: block;
        }
      }

      .main_heading {
        top: 0;
        bottom: 0;
        position: absolute;
        margin: auto;
        height: 250px;
        left: 0;
        width: 100%;
      }
      
    }
  }

}

.page_header {
  width: 100%;
  height: 200px;
  overflow: hidden;
  background: #F5F8FA;

  &.page_about {
    background: url("../img/bg/bg_about.jpg") center;
    background-size: cover;
  }

  &.page_restaurant {
    background: url("../img/bg/bg_restaurant.jpg") center;
    background-size: cover;
  }

  &.page_contacts {
    background: url("../img/bg/bg_contacts.jpg") center;
    background-size: cover;
  }

  &.page_city {
    background: url("../img/bg/bg_city.jpg") center;
    background-size: cover;
  }

  &.page_service {
    background: url("../img/bg/bg_couriers-service.jpg") center;
    background-size: cover;
  }

  &.page_store {
    background: url("../img/bg/bg_store.jpg") center;
    background-size: cover;
  }

  &.page_mailing {
    background: url("../img/bg/bg_mailing.jpg") center;
    background-size: cover;
  }

  &.page_couriers {
    background: url("../img/bg/bg_couriers.jpg") center;
    background-size: cover;

    @media (max-width: 767px) {
      background: url("../img/bg/bg_couriers_m.jpg") no-repeat center top;
      background-size: cover;
    }
  }

  &.bg_heading {
    height: 300px;
    position: relative;

    .heading {
      font-weight: bold;
      font-size: 64px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      color: #fff;
      padding-bottom: 0;
      height: 114px;
      text-align: center;
    }

    @media (max-width: 1100px) {
      .heading {
        font-size: 48px;
      }
    }

    @media (max-width: 767px) {
      .heading {
        font-size: 32px;
      }
    }
  }
}
/* end page_header */

.new_format {
  background: #F5F8FA;
  padding-bottom: 24px;

  .top_content {
    background: #fff;
    padding: 20px 28px 0;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 8px 8px;
    margin: 0 -15px 48px -15px;

    p {
      color: #333;
      font-size: 16px;
      line-height: 22px;
      margin: 0;
    }

    .image {
      display: table-cell;
      padding-bottom: 0!important;
      padding-right: 8px;
    }

    .item {
      display: table;
      padding-bottom: 20px;
    }

    .text {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
    }
    
    @media (max-width: 767px) {
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  .description {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    letter-spacing: 0.01em;

    color: #333333;
    padding-bottom: 24px;
  }

  .item {
    text-align: center;
    padding-bottom: 24px;

    .image {
      padding-bottom: 16px;
    }

    .text {
      font-size: 16px;
      color: #333333;

      p {
        margin: 0;
      }
    }
  }
}

.your_business {
  padding: 24px 0 18px;

  h2, .heading {
    color: #333;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 24px;
  }

  a {
    color: #333;
    text-decoration: none;
  }

  .item {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.175);
    overflow: hidden;
    margin-bottom: 30px;
    min-height: 267px;

    .image img {
      width: 100%;
    }

    .title {
      color: #333;
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 8px;
      line-height: 24px;
    }

    .content {
      font-size: 16px;
      padding: 20px 20px 24px;

      p {
        margin: 0;
      }
    }
  }
}

.special_conditions {
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.175);
  border-radius: 8px;

  .image {
    background: url("../img/bg_special.jpg");
    background-size: cover;
    border-radius: 8px 8px 0px 0px;
    height: 97px;
  }
  .content {
    color: #fff;
    background: #6FCF97;
    padding: 21px;
    font-weight: bold;
    border-radius: 0px 0px 8px 8px;

    p, h5 {
      margin: 0;
    }

    .btn {
      margin: 0 16px;
    }

    @media (max-width: 767px) {
      .btn {
        margin: 16px 0 0;
        width: 100%;
      }
    }
  }
}

.trust_us {
  padding-top: 48px;
  padding-bottom: 48px;
}

.reviews {
  padding: 40px 0 40px;

  .item_review {
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 24px;
    position: relative;

    a {
      text-decoration: none;
    }

    .image {
      padding-bottom: 16px;
      text-align: center;
    }

    .text {
      font-size: 16px;
      text-align: center;
      padding-bottom: 15px;

      color: #333333;
    }

    .author {
      line-height: 19px;
      font-size: 13px;
      text-align: center;
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;

      color: #828282;
    }
  }

  .owl-dots {
    text-align: center;

    .owl-dot {
      width: 10px;
      height: 10px;
      border: 2px solid #E0E0E0;
      border-radius: 50%;
      margin: 0 7px;
      display: inline-block;
      outline: none;

      &.active {
        background: #E0E0E0;
      }
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 24px;
  }
}

.work_courier {
  background: #F5F8FA;
  padding: 48px 0;

  .description {
    font-weight: 300;
    line-height: 30px;
    font-size: 20px;
    letter-spacing: 0.01em;

    color: #333333;
  }

  .text {
    font-size: 16px;

    color: #333333;
  }

  .image {
    padding-bottom: 24px;
  }

  @media (max-width: 767px) {
    .btn {
      margin: 16px 0 0;
      width: 100%;
    }
  }
}

.about_us {
  padding: 48px 0;
  p {
    font-size: 16px;
    color: #333333;
  }
}

.news {
  padding: 24px 0;

  h2, .heading {
    a {
      font-size: 16px;
      text-decoration-line: underline;
      color: #4BA8C4;
      padding: 0 24px;
      font-weight: normal;
      display: inline-block;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .item_news {
    padding-bottom: 30px;

    .date {
      line-height: 19px;
      font-size: 13px;
      padding-bottom: 8px;
      color: #828282;
    }

    .text p {
      font-size: 16px;
      margin: 0;
      color: #333333;
    }
  }

  .btn {
    width: 100%;
  }
}

.vacancy {
  padding: 18px 0;

  .item_vacancy {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 25px 20px 20px;
    margin-bottom: 30px;

    .title,
    .title a {
      font-size: 28px;
      font-weight: bold;
      color: #333333;
    }

    .location {
      font-family: 'PT Sans Caption', sans-serif;
      font-size: 18px;
      letter-spacing: 0.01em;
      padding-top: 18px;
      color: #4BA8C4;
    }
  }
}

/* delivery */
.easy_order {
  padding: 48px 0;

  &.bg_gradient {
    background: linear-gradient(180deg, rgba(197, 227, 236, 0.5) 0%, rgba(197, 227, 236, 0) 55%);

    @media (max-width: 992px) {
      background: linear-gradient(180deg,rgba(197,227,236,.5) 0,rgba(197,227,236,0) 25%);
    }
  }

  .item {
    padding-bottom: 24px;

    .image {
      margin-bottom: 16px;
      position: relative;

      .wrap_image {
        border-radius: 8px 8px 8px 25px;
        height: 150px;
        overflow: hidden;
        position: relative;
        z-index: 8;

        img {
          border-radius: 8px;
        }

        @media (max-width: 1100px) {
          img {
            width: 100%;
          }
        }
      }

      .count_circle {
        color: #fff;
        font-size: 32px;
        line-height: 55px;
        font-weight: bold;
        width: 80px;
        height: 80px;
        background: #4BA8C4;
        text-align: center;
        border: 12px solid #fff;
        border-radius: 50%;
        position: absolute;
        left: -10px;
        bottom: -10px;
        z-index: 10;
      }
    }

    .text {
      font-weight: 300;
      line-height: 30px;
      font-size: 20px;
      letter-spacing: 0.01em;
      color: #333333;
    }
    
    @media (min-width: 992px) {
      .wrap_image {
        height: 180px!important;
      }
    }
  }

  .button {
    text-align: center;
  }

}

.tarif_pricing {
  background: #F5F8FA;
  padding: 48px 0 33px;

  .card_item {
    overflow: hidden;
    margin-bottom: 15px;

    .header {
      svg {
        width: 100%;
        height: 50px;
      }
    }

    .content {
      padding: 13px 20px 20px;
    }

    .title {
      font-weight: bold;
      font-size: 24px;
      color: #333;
      padding-bottom: 16px;
    }

    .description {
      font-size: 16px;
      color: #828282;

      p {
        margin: 0;
      }
    }

    .footer {
      padding-top: 8px;

      .price {
        font-weight: bold;
        font-size: 24px;
        color: #4BA8C4;
        padding-bottom: 2px;

        .hit {
          color: #fff;
          font-size: 10px;
          background: url("../img/hit.svg") no-repeat;
          text-align: center;
          font-weight: bold;
          padding: 2px 12px 2px 10px;
          text-transform: uppercase;
          margin-left: 8px;
          position: relative;
          top: -5px;
        }
      }

      .tax {
        line-height: 19px;
        font-size: 13px;
        color: #828282;
      }
    }
  }

  .button {
    text-align: center;
    padding-top: 24px;

    a {
      text-decoration: underline;
      font-size: 16px;
      color: #4BA8C4;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.opportunities {
  padding: 48px 0;

  .content_bottom {
    padding-top: 33px;
  }

  .card_item {
    padding: 20px;
    box-shadow: none;
    border: 1px solid #E0E0E0;
    text-align: center;
    margin-bottom: 15px;

    @media (min-width: 992px) {
      height: 275px;
    }

    .image {
      padding-bottom: 16px;
    }

    .title {
      font-weight: bold;
      font-size: 20px;
      padding-bottom: 8px;
      line-height: 24px;
    }

    .text {
      font-size: 16px;
      color: #828282;
    }
  }

  .item_mini {
    text-align: center;
    padding-bottom: 16px;

    .image {
      padding-bottom: 8px;
    }
    .title {
      font-size: 16px;
      color: #333;
    }
  }
}

.map_content {
  padding: 48px 0;
}

.easy_method {
  .item {
    text-align: center;
    padding-bottom: 28px;

    .image {
      padding-bottom: 16px;
    }

    .description {
      font-size: 16px;
      color: #333;
    }

    .button {
      padding-top: 16px;
    }
  }
}

/* about */
.about_content {
  padding: 48px 0 48px;
}

.about_info {
  padding: 48px 0;

  .panel_items {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .card_item {
    margin-bottom: 24px;
    width: 100%;
    overflow: hidden;

    &.height {
      @media (min-width: 576px) {
        min-height: 295px;
      }
    }

    .content {
      padding: 20px;
    }

    .header {
      height: 50px;

      svg {
        width: 100%;
        height: 50px;
      }
    }

    .title {
      font-weight: bold;
      font-size: 24px;
      color: #333333;
      padding-bottom: 8px;
      line-height: 28px;
    }

    .description {
      line-height: 19px;
      font-size: 13px;
      color: #828282;

      p {
        margin: 0;
      }
    }
  }
}

.delivery_success {
  text-align: center;

  .persent {
    font-weight: bold;
    font-size: 40px;
    color: #333333;
  }

  .description {
    font-size: 16px;
    color: #333333;
  }
}

.achievement {
  padding-top: 48px;

  .item_card {
    padding: 20px;
    text-align: center;
    box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.175);
    margin-bottom: 15px;

    .count {
      font-size: 64px;
      font-weight: bold;
      color: #4BA8C4;
    }

    .description {
      font-size: 20px;
      font-weight: 300;
      letter-spacing: 0.01em;
      color: #333333;
    }
  }
}

.about_info {
  background: #F5F8FA;
}

/* couriers */
.mobile_app {
  background: #F5F8FA;
  padding-top: 48px;

  .description {
    font-weight: 300;
    line-height: 30px;
    font-size: 20px;
    letter-spacing: 0.01em;
    color: #333;
  }

  .text {
    color: #333;
    font-size: 16px;
    padding: 8px 0;
  }

  .column_left {
    padding-bottom: 48px;
  }

  .btn {
    width: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .links_app {
    padding-top: 24px;

    .text {
      font-size: 16px;
      color: #333;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
    }

    li {
      list-style: none;
      padding-right: 24px;
    }
  }

  .image_mobile {
    text-align: center;
    padding-bottom: 16px;
  }

  .mob {
    width: 100%;
  }

  @media (min-width: 990px) {
    .image_mobile {
      float: right;
      position: relative;
      bottom: -14px;
      padding-bottom: 0;

      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 990px) {
    .image_mobile {
      margin: 0 15px;
    }
  }
}

.example_video {
  padding: 48px 0 18px;

  .card_item {
    margin-bottom: 30px;

    .image {
      img {
        width: 100%;
      }

      a {
        position: relative;

        &:before {
          content: "";
          background: url("../img/icons/play-button.svg") no-repeat;
          width: 48px;
          height: 48px;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
        }
      }
    }

    .content {
      padding: 20px;
    }

    .title {
      padding-bottom: 8px;

      a {
        font-weight: bold;
        font-size: 20px;
        color: #4BA8C4;
      }
    }

    .time {
      line-height: 19px;
      font-size: 13px;
      color: #333333;
      padding-bottom: 8px;
    }

    .text p {
      font-size: 16px;
      color: #828282;
      line-height: 22px;
    }

    @media (min-width: 767px) and (max-width: 1090px) {
      height: 330px;
    }

    @media (min-width: 1100px) {
      height: 290px;
    }

  }
}

.advantages_work {
  .item {
    text-align: center;
    padding-bottom: 24px;

    .image {
      padding-bottom: 8px;
    }

    .text p {
      font-size: 16px;
      margin: 0;
      color: #272F3C;
    }
  }
}

.faq_content {
  padding-top: 32px;
  padding-bottom: 8px;

  .item_faq {
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 16px;
    margin-bottom: 16px;

    &:last-child {
      border-bottom: 0;
    }

    .title {
      font-weight: bold;
      font-size: 21px;
      color: #333333;
      font-family: "PT Sans", sans-serif;
    }

    .comment {
      font-size: 16px;
      color: #333333;

      p {
        margin-bottom: 0;
      }
    }
  }
}

.info_delivery {
  padding: 48px 0 33px;
}

/* clients */
.delivery_business {
  padding: 48px 0;

  &.background {
    background: linear-gradient(180deg,rgba(197,227,236,.5) 0,rgba(197,227,236,0) 80%);
  }

  .description {
    color: #333;
    font-weight: 300;
    line-height: 30px;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    padding-bottom: 16px;
  }

  .text {
    color: #333;
    font-size: 16px;
    text-align: center;
  }

  .button {
    padding-top: 8px;
    text-align: center;
  }
}

.delivery_ways {
  padding: 72px 0 0;

  .item {
    text-align: center;
    padding-bottom: 24px;

    .image {
      padding-bottom: 16px;
    }

    .title {
      padding-bottom: 16px;
      font-size: 16px;
      color: #333;
    }

    a.link {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    .btn {
      width: 100%;
    }
  }

}

.simple_content {
  .item_card {
    margin-bottom: 30px;

    img {
      width: 100%;
    }

    .content {
      padding: 20px;
    }

    .title {
      font-weight: bold;
      font-size: 20px;
      color: #333;
      padding-bottom: 8px;
      line-height: 26px;
    }

    .image {
      max-height: 155px;
      overflow: hidden;
    }

    .text {
      font-size: 16px;
      color: #828282;
      p {
        margin: 0;
      }
    }

    @media (min-width: 576px) {
      height: 267px;
    }

    &.big_card {
      height: auto;
    }

    @media (min-width: 992px) {
      &.big_card {
        height: 240px;
      }
    }
  }
}

.advantages {
  background: #F5F8FA;
  padding: 48px 0 32px;
  margin: 48px 0;

  .item {
    padding-bottom: 16px;
    display: table;

    .title {
      font-size: 16px;
      line-height: 24px;
      padding-top: 5px;
      color: #333;
    }

    .image {
      padding-right: 18px;
      display: table-cell;
      vertical-align: middle;

      img {
        width: 32px;
      }
    }
  }
}

.send_request {
  background: url("../img/bg/sendform.jpg") repeat;
  padding: 48px 0;

  .description {
    font-size: 16px;
    text-align: center;
    color: #333;
  }

  .form_content {
    background: #fff;
    width: 100%;
    padding: 20px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin: 0 auto;

    .form-control {
      text-align: center;
    }

    .button {
      margin: 0;
    }

    .btn {
      width: 100%;
      padding: 14px 24px;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
    
    @media (min-width: 992px) {
      padding: 48px;
      width: 540px;
    }
  }

  .contacts {
    text-align: center;
    padding-top: 24px;
    .or {
      font-size: 16px;
      color: #333;
    }

    .phone {
      a {
        font-weight: bold;
        font-size: 28px;
        color: #333333;
        text-decoration: none;
      }
    }

    .work_time {
      line-height: 19px;
      font-size: 13px;
      color: #828282;
    }
  }

}

.store_direction {
  .content_bottom {
    padding-top: 32px;
  }

  .card_list {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .item {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
  }

  .card {
    margin-bottom: 16px;
    border-radius: .25rem;
    width: 100%;

    .content {
      padding: 16px 20px 4px;
    }

    &.card_item {
      border: 1px solid #E0E0E0;
      box-shadow: none;
      padding-top: 20px;
    }

    &.card_top_item {
      padding-bottom: 12px;

      .image {
        height: 120px;
        overflow: hidden;
      }

      img {
        width: 100%;
      }
    }
  }
}