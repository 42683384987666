header {
  li {
    a.dropdown-city {
      border: 1px solid #C5E3EC;
      border-radius: 100px;
      padding: 8px 24px!important;
      outline: none;

      &:after {
        color: #4ba8c4;
      }
    }
  }

  @media (min-width: 990px) {
    li.dropdown {
      padding-right: 35px;
    }
  }

  @media (max-width: 990px) {
    .navbar-collapse {
      text-align: center;

      &.show {
        margin-top: 14px;
      }

      li {
        margin-bottom: 8px;
      }

      a {
        display: inline-block;
      }
    }
  }
}