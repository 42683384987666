#footer {
  background: #01394C;
  padding: 24px 0;
  line-height: 19px;

  .footer_nav {
    a {
      color: #4BA8C4;
      font-size: 13px;
    }

    li {
      display: block;
      width: 100%;
      padding-bottom: 8px;
    }
  }

  .nav_term {
    a {
      color: #4BA8C4;
      font-size: 13px;
    }

    li {
      padding-right: 24px;
    }
  }

  .about_footer {
    color: #fff;
    font-size: 13px;
  }

  .contacts, .address {
    color: #fff;
    font-size: 13px;

    p {
      margin-bottom: 0.5rem;
    }
  }

  .address {
    margin-top: 2rem;
  }

  .footer_social {
    padding-top: 2.3rem;

    li {
      margin: 0 12px;

      &:first-child {
        margin-left: 0;
      }
    }

    @media (min-width: 1100px) {
      float: right;
    }
  }

  .payments {
    padding-top: 24px;
    display: flex;

    .item {
      p {
        color: #fff;
        line-height: 19px;
        font-size: 13px;
        margin: 0;
      }

      &:first-child {
        margin-right: 16px;
        padding: 10px 0;
      }

      ul {
        margin: 0;
        padding: 8px 0 0;
        display: inline-block;

        li {
          padding: 0 8px;
          list-style: none;
          display: inline-block;

          &:first-child {
            padding: 0;
          }
        }
      }
    }
  }

  @media (max-width: 1100px) {
    .nav_term {
      padding-top: 8px;

      li {
        padding-bottom: 12px;
      }
    }

    .contacts {
      padding-top: 12px;
    }
  }

  @media (max-width: 767px) {
    .payments {
      display: block;
      padding-top: 6px;

      .payments_method {
        li {
          &:first-child {
            display: block;
            padding-bottom: 6px!important;
          }

          &:nth-child(2) {
            padding-left: 0;
          }
        }
      }
    }

    .footer_social {
      padding-top: 24px;
    }
  }
}